<script>
    export default{
        props: {
            justifyContent: { type: String, default: 'center'},
            justifyItems: { type: String, default: 'auto'},
            alignItems: { type: String, default:'center'},
            wrap: { type: Boolean, default: false},
            direction: { type: String, default: 'row'},
            component: { type: String, default: 'div'}
        },
        computed:{
            flexWrap(){
                return (this.wrap === true) ? "wrap" : "nowrap";
            },
        },
     }
</script>

<template>
    <component :is="component" :style="{'display': 'flex', 'justify-items': justifyItems, 'flex-direction' : direction, 'justify-content': justifyContent, 'align-items': alignItems, 'flex-wrap': flexWrap, }">
        <slot></slot>
    </component>
</template>