<template>
  <b-modal
    :title="creationMode ? creationModeTitle : editionModeTitle"
    centered
    :static="true"
    :lazy="true"
    v-model="modalShown"
    button-size="lg"
    @ok="handleOk"
    @close="close"
    @hidden="hide"
    @show="show"
    ref="dialog"
  >
      <vue-bootstrap-typeahead
          v-show = 'creationMode && !newPeople'
          v-model="editSearch"
          :data="searchSuggestions"
          :minMatchingChars="2"
          :serializer="s => s.forSuggestion"
          placeholder="par nom, prénom, n° de licence"
          @hit="editPeopleSearch_Hit"
          prepend="Rechercher"
          ref="peopleSearchArea"
      >
        <template slot="suggestion" slot-scope="{ data, htmlText }">
          <span v-if="data.matches=='**'" class="full-match" v-html="htmlText"></span>
          <span v-if="data.matches=='*'" class="half-match" v-html="htmlText"></span>
          <span v-if="data.matches==''" class="no-match" v-html="htmlText"></span>
        </template>
      </vue-bootstrap-typeahead>

    <!--</b-input-group>-->
      <helper-note v-show="creationMode" :variant="creationHelperNoteVariant">
        <slot name="creationMode-helperNote-content">
          <div>Privilégiez toujours la recherche.<br/>
          Si aucun résultat ne correspond, essayez de modifier l'orthographe.<br/>
          Si, finalement, vous ne trouvez vraiment pas la personne recherchée, ou si vous êtes certain qu'il s'agit d'une nouvelle personne inconnue dans la base, choisissez "Nouveau" puis saisissez les informations ci-dessous.
          </div>
        </slot>
          <b-button variant="secondary" size="sm" @click="newPeople_click" :disabled="newPeople">
            <slot name="new-people-button-text">Nouveau</slot>
          </b-button>
          <b-button variant="secondary" size="sm" @click="cancelNewPeople_click" :disabled="!newPeople">
            <slot name="cancel-new-people-button-text">Annuler Nouvelle personne</slot>
          </b-button>
      </helper-note>
<!--      <b-alert v-model="creationMode" variant="info">
<div>Privilégiez toujours la recherche.<br/>
          Si aucun résultat ne correspond, essayez de modifier l'orthographe.<br/>
          Si, finalement, vous ne trouvez vraiment pas le compétiteur, ou si vous êtes certain qu'il s'agit d'un nouveau compétiteur inconnu dans la base, choisissez "Nouveau" puis saisissez les informations ci-dessous.
          </div>
          <b-button variant="secondary" size="sm">Nouveau</b-button>      </b-alert>-->
    <b-form ref="form" @submit.stop.prevent="editPeople_Ok">

      <form-field caption="Prénom" required>
        <b-form-input type="text"
          v-model="editingPeopleFirstname"
          required
          :disabled="creationMode && !newPeople"
          :state="editingPeopleFirstnameIsValid"
          @blur="checkEditingPeopleFirstnameValidity"
          @input="editingPeopleFirstnameIsValid = null"
          invalidFeedBack="Le prénom est obligatoire."
        />
      </form-field>
      <form-field caption="Nom" required>
        <b-form-input type="text"
          v-model="editingPeopleLastname"
          required
          :disabled="creationMode && !newPeople"
          :state="editingPeopleLastnameIsValid"
          @blur="checkEditingPeopleLastnameValidity"
          @input="editingPeopleLastnameIsValid = null"
        />
      </form-field>
      <form-field label="N° de licence FFVL" :required="d_federal_number_required">
        <b-form-input type="text"
          v-model="editingPeopleFederalNumber"
          :disabled="creationMode && !newPeople"
          :required="d_federal_number_required"
          :state="editingPeopleFederalNumberIsValid"
          @blur="checkEditingPeopleFederalNumberValidity"
          @input="editingPeopleFederalNumberIsValid = null"
        />
      </form-field>
      <licensee-state 
        v-show="editingPeopleFederalNumber != null && editingPeopleFederalNumber.length==8"
        :federalNumber="editingPeopleFederalNumber"
        :showSummary="false"
        @change="licenseeState_change"
      >
        <template #prepend="data">
          <b>Etat de la licence {{ data.year }} de {{ editingPeopleFirstname || editingPeopleLastname || 'cette personne' }}</b>
        </template>
      </licensee-state>
      <form-field label="Sexe" required v-show="creationMode && newPeople">
        <b-form-radio-group
          v-model="editingPeopleSexe"
          :required="creationMode && newPeople"
          :state="editingPeopleSexeIsValid"
          @blur="checkEditingPeopleSexeValidity"
          @input="editingPeopleSexeIsValid = null"
        >
          <b-form-radio value="M" :state="editingPeopleSexeIsValid">Homme</b-form-radio>
          <b-form-radio value="F" :state="editingPeopleSexeIsValid">Femme</b-form-radio>
        </b-form-radio-group>
      </form-field>
      <form-field label="birthDate" v-show="creationMode && newPeople">
        <!--<b-form-datepicker v-model="editingPeopleBirthdate" />-->
        <b-form-input type="date"
          v-model="editingPeopleBirthdate"
          :min="minBirthdateYear + '-01-01'"
          :max="maxBirthdateYear + '-12-31'"
          :state="editingPeopleBirthdateIsValid"
          @blur="checkEditingPeopleBirthdateValidity"
          @input="editingPeopleBirthdateIsValid = null"
        />
      </form-field>
      <form-field label="courriel" v-show="creationMode && newPeople">
        <b-form-input type="email"
          v-model="editingPeopleEmail"
          :state="editingPeopleEmailIsValid"
          @blur="checkEditingPeopleEmailValidity"
          @input="editingPeopleEmailIsValid = null"
         />
      </form-field>
    </b-form>
  </b-modal>
</template>

<script>
//import { RST_COMPETITION } from "@/LocalDB";
import FormField from "@/components/Panels/FormField.vue";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import HelperNote from "@/components/HelperNote";
//import CompetitionsConfigurationHelper from "@/services/helpers/competitionsConfigurationHelper";
import { debounce } from "debounce";
import LicenseeState from "@/components/LicenseeState.vue";

export default {
  name: 'PeopleEditDialog',
  components:{ FormField, VueBootstrapTypeahead, HelperNote, LicenseeState },
  props: {
    value: { type:Boolean, default: false},
    editCallContext : { type: Object, default: null},
    creationMode: { type:Boolean, default: false},
    creationModeTitle: { type:String, default:"Ajout d'individu"},
    editionModeTitle: { type: String, default: "Modification d'individu"},
    search_suggestions: { type:Array, default: null},
    fedralNumberRequired: { type: Boolean, default: false },
  },
  data(){
    return {
      VERBOSE: false,

      modalShown: this.value,
      editSearch: '',
      //editSearchSuggestions: this.search_suggestions,

      d_federal_number_required: this.fedralNumberRequired,

//      editingPeopleId: this.People_id,
      editingPeopleFirstname : null, editingPeopleFirstnameIsValid: null,
      editingPeopleLastname: null,  editingPeopleLastnameIsValid: null,
      editingPeopleFederalNumber: null, editingPeopleFederalNumberIsValid: null,
      editingPeopleSexe: null, editingPeopleSexeIsValid: null,
      editingPeopleBirthdate: null, editingPeopleBirthdateIsValid: null,
      editingPeopleEmail: null, editingPeopleEmailIsValid: null,

      searchSuggestions: this.search_suggestions,
      newPeople: false,
      creationHelperNoteVariant: 'info',

      b_edit_call_context: this.editCallContext,
      
      d_updated_licensee_state: null,

      d_competition_id: this.competition_id,
    };
  },
  computed:{
    /*COMPETITION(){
      var comp = RST_COMPETITION.query().where("competition_id", this.d_competition_id).first();
      return comp;
    },*/
    minBirthdateYear(){
      return new Date().getFullYear() - 80;
    },
    maxBirthdateYear(){
      return new Date().getFullYear() - 5;
    }
  },
  methods:{
    checkFormValidity(){
      this.checkEditingPeopleFirstnameValidity();
      this.checkEditingPeopleLastnameValidity();
      this.checkEditingPeopleFederalNumberValidity();
      this.checkEditingPeopleSexeValidity();
      this.checkEditingPeopleBirthdateValidity();
      this.checkEditingPeopleEmailValidity();

      const valid = this.$refs.form.checkValidity();
      return valid;
    },
    handleOk(bvModalEvent){
      bvModalEvent.preventDefault();
      this.editPeople_Ok();
    },
    close(){
      this.$emit("close");
      this.$emit("input", false);
    },
    hide(){
      this.$emit("hide");
      this.$emit("input", false);
    },
    hideDialog(){
      this.$refs['dialog'].hide();
      this.$emit("input", false);
    },
    show(){
      this.reset(!this.creationMode && this.editingPeopleId != null);
      this.$emit("input", true);
    },
    editPeopleSearch_Hit(selected){
      if(this.VERBOSE) console.log("selected", selected);
      this.editingPeopleId = selected;
      this.editingPeopleFirstname = selected.firstname;
      this.editingPeopleLastname = selected.lastname;
      this.editingPeopleFederalNumber = selected.federal_number || selected.federalNumber;
    },
    async editPeople_Ok(){
      if(!this.checkFormValidity()) {
        return;
      }
      this.hideDialog();

      var comp = this.editingPeopleId;
      if(this.VERBOSE) console.log("editPeople_Ok", this.creationMode, comp);
      if(this.creationMode)
        if(comp != null)
          this.$emit('select', { 
                                context: this.b_edit_call_context, 
                                people: comp, 
                                licenseeState: this.d_updated_licensee_state 
          });
        else
          this.$emit('create', {
                                context: this.b_edit_call_context,
                                firstname: this.editingPeopleFirstname,
                                lastname: this.editingPeopleLastname,
                                federal_number: this.editingPeopleFederalNumber,
                                sexe: this.editingPeopleSexe,
                                birthdate: this.editingPeopleBirthdate,
                                email: this.editingPeopleEmail,
                                licenseeState: this.d_updated_licensee_state
          });
      else{
        var editArg = {
          context: this.b_edit_call_context,
          id: comp,
          firstname: this.editingPeopleFirstname,
          lastname: this.editingPeopleLastname,
          federal_number: this.editingPeopleFederalNumber,
          sexe: this.editingPeopleSexe,
          birthdate: this.editingPeopleBirthdate,
          email: this.editingPeopleEmail,
          licenseeState: this.d_updated_licensee_state
        };
        if(this.VERBOSE) console.log('emit EDIT/UPDATE', editArg);
        //this.$emit('edit', editArg );
        this.$emit("update", editArg);
      }
    },
    newPeople_click(){
      this.newPeople = true;
      this.creationHelperNoteVariant = 'danger';
    },
    cancelNewPeople_click(){
      this.newPeople = false;
      this.editingPeopleFirstnameIsValid = null;
      this.editingPeopleLastnameIsValid = null;
      this.editingPeopleFederalNumberIsValid = null;
      this.editingPeopleSexeIsValid = null;
      this.editingPeopleBirthdateIsValid = null;
      this.editingPeopleEmailIsValid = null;
      this.creationHelperNoteVariant = 'info';
    },
    reset(exceptEditionFields = false){
      this.editSearch = '';

      if(!exceptEditionFields){
        this.editingPeopleId = null;
        this.editingPeopleFirstname = '';
        this.editingPeopleLastname = '';
        this.editingPeopleFederalNumber = '';
      }
      this.editingPeopleFirstnameIsValid = null;
      this.editingPeopleLastnameIsValid = null;
      this.editingPeoplerFederalNumberIsValid = null;
      this.editingPeoplerSexe = null;              this.editingPeopleSexeIsValid = null;
      this.editingPeopleBirthdate = null;         this.editingPeoplerBirthdateIsValid = null;
      this.editingPeopleEmail = null;             this.editingPeopleEmailIsValid = null;
      this.newPeople = false;
      this.creationHelperNoteVariant = 'info'
    },
    checkEditingPeopleFirstnameValidity(){
      this.editingPeopleFirstnameIsValid = null;
      if((!this.creationMode || this.newPeople) && (this.editingPeopleFirstname == null || this.editingPeopleFirstname.trim() == ''))
        this.editingPeopleFirstnameIsValid = false;
      return this.editingPeopleFirstnameIsValid == null ? true : false;
    },
    checkEditingPeopleLastnameValidity(){
      this.editingPeopleLastnameIsValid = null;
      if((!this.creationMode || this.newPeople) && (this.editingPeopleLastname == null || this.editingPeopleLastname.trim() == ''))
        this.editingPeopleLastnameIsValid = false;
      return this.editingPeopleLastnameIsValid == null ? true : false;
    },
    checkEditingPeopleFederalNumberValidity(){
      this.editingPeopleFederalNumberIsValid = null;
//      if((!this.creationMode || this.newPeople) && (this.editingPeopleFederalNumber == null || this.editingPeopleFederalNumber.trim() == '') && this.COMPETITION.COMPETITION_TYPE.isFederal && !this.COMPETITION.isOpen )
      if((!this.creationMode || this.newPeople) && (this.editingPeopleFederalNumber == null || this.editingPeopleFederalNumber.trim() == '') && this.d_federal_number_required )
        this.editingPeopleFederalNumberIsValid = false;
      return this.editingPeopleFederalNumberIsValid == null ? true : false;
    },
    checkEditingPeopleSexeValidity(){
      this.editingPeopleSexeIsValid = null;
      if(this.creationMode && this.newPeople && (this.editingPeopleSexe == null || this.editingPeopleSexe.trim() == ''))
        this.editingPeopleSexeIsValid = false;
      return this.editingPeopleSexeIsValid == null ? true : false;
    },
    checkEditingPeopleBirthdateValidity(){
      if(this.VERBOSE) console.log('checkEditingPeopleBirthdateValidity', this.editingPeopleBirthdate);
      this.editingPeopleBirthdateIsValid = null;
      if(this.editingPeopleBirthdate != null){
        var y = this.editingPeopleBirthdate.slice(0, 4);
        if(y < this.minBirthdateYear || y > this.maxBirthdateYear )
          this.editingPeopleBirthdateIsValid = false;
      }
      return this.editingPeopleBirthdateIsValid == null ? true : false;
    },
    checkEditingPeopleEmailValidity(){
      this.editingPeopleEmailIsValid = null;
      if(this.editingPeopleEmail != null && this.editingPeopleEmail != '' && !this.validateEmail(this.editingPeopleEmail))
        this.editingPeopleEmailIsValid = false;
      return this.editingPeopleEmailIsValid == null ? true : false;
    },
    licenseeState_change(newState){
      this.d_updated_licensee_state = newState;
    },
    emitSearch(search){
      this.$emit('search', search);
    }
  },
  watch:{
    editSearch: debounce(function(search) { this.emitSearch(search)}, 200),
    search_suggestions: function (newVal){
        this.searchSuggestions = newVal;
    },

    value: function(newVal)
    {
      this.modalShown = newVal;
    },
    People_id: function(newVal)
    {
        this.editingPeopleId = newVal;
        this.editingPeopleFirstname = newVal.firstname;
        this.editingPeopleLastname = newVal.lastname;
        this.editingPeopleFederalNumber = newVal.federal_number;
    },
    editCallContext: function(newVal){
      if(this.VERBOSE) ('editCalContext change (watch)', newVal)
      this.b_edit_call_context=newVal;
      this.editingPeopleId = newVal ? newVal.people_id : '';
      this.editingPeopleFirstname = newVal ? newVal.firstname : '';
      this.editingPeopleLastname = newVal ? newVal.lastname : '';
      this.editingPeopleFederalNumber = newVal ? (newVal.federal_number || ( (typeof newVal.federalNumber == "object") ? newVal.federalNumber.number : newVal.federalNumber)) : '';
    }
  }
}
</script>

<style scoped>
  .full-match { color: green; font-weight: bolder;}
  .half-match { color: goldenrod;}
  .no-match{ color: red;}
</style>
