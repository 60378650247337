<script>
import { RST_COMPETITOR, /*RST_COMPETITOR_COMPOSITION,*/ RST_COMPETITION, REF_CATEGORY, COMPETITOR, PEOPLE } from "@/LocalDB";
import FormField from '@/components/Panels/FormField.vue';
import TeamMembersList from '@/components/TeamMembersList.vue';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import HelperNote from "@/components/HelperNote";
import CompetitionsConfigurationHelper from "@/services/helpers/competitionsConfigurationHelper";
import PeopleEditDialog from "./PeopleEditDialog.vue"
import LicenseeEditDialog from './LicenseeEditDialog.vue';
import CheckboxFormField from "@/components/Panels/CheckboxFormField.vue";
import debounce from "debounce";
import { LICENSEE, RST_COMPETITOR_COMPOSITION } from '../../LocalDB';
import FlexPanel from '@/components/Panels/FlexPanel.vue'
import FederalNumber from '../Panels/FederalNumber.vue';

export default {
  components: { FormField, TeamMembersList, VueBootstrapTypeahead, HelperNote, PeopleEditDialog, LicenseeEditDialog, CheckboxFormField, FlexPanel, FederalNumber },
  props: {
    value: { type:Boolean, default: false},
    competition_id : { type:String, required: true},
    level: { type:String, required: true},
    category: { type: String, required: true},
    competitor_id: { type: Object, default: null},
    creationMode: { type:Boolean, default: false},
    search_suggestions: { type:Array, default: null},
    members: { type:Array, required: false },
    minMemberCount: { type:Number, required: true},
    maxMemberCount: { type:Number, required: true},
  },
  data(){
    return {
      VERBOSE: false,
      modalShown: this.value,
      editSearch: '',

      d_editingCompetitorId: this.competitorId,
      editingCompetitorName : (this.d_editingCompetitorId != null) ? this.d_editingCompetitorId.name : null, editingCompetitorNameIsValid: null,
      editingCompetitorShortname : (this.d_editingCompetitorId != null) ? this.d_editingCompetitorId.shortname : null, editingCompetitorShortnameIsValid: null,
      editingCompetitorIsOpen: false,
      d_editingTeamCompetitors: this.members || [], //(this.d_editingCompetitorId != null && this.d_editingCompetitorId.members != null) ? this.d_editingCompetitorId.members.map(a => a) : [],

      searchSuggestions: [],
      newCompetitor: false,
      creationHelperNoteVariant: 'info',

      d_showPeopleEditDialog: false,
      d_peopleEditDialogContext: {},
      d_peopleEditDialogCreationMode: false,
      d_peopleEditDialogSearchSuggestions: [],
      
      showEditLicenseeDialog: false,
      selectedLicensee: '',
      selectedLicencePeopleName: '',
      selectedLicenceYear: null,

      d_category : this.category,
      d_minMemberCount : this.minMemberCount,
      d_maxMemberCount: this.maxMemberCount,

      membersSlots : [],
    };
  },
  computed:{
    COMPETITION(){
      var comp = RST_COMPETITION.query().where("competition_id", this.competition_id).first();
      return comp;
    },
    CATEGORY(){
      var cat = REF_CATEGORY.query().where("code", this.category).first();
      return cat;
    },
    competitionIsOpen(){
      return this.COMPETITION.isOpen;
    },
  },
  methods:{
    refreshMembersSlots(){
      if(this.VERBOSE) console.log('refreshMembersSlots A', this.d_minMemberCount, this.d_editingTeamCompetitors);
      var m = this.d_minMemberCount;
      if(this.d_editingTeamCompetitors.length > m)
        m = this.d_editingTeamCompetitors.length;
      if(this.d_editingTeamCompetitors.length >= this.d_minMemberCount && this.d_editingTeamCompetitors.length < this.d_maxMemberCount)
        m++;
      var ret = [];
      for(var i= 0; i < m; i++ ){
        if(i < this.d_editingTeamCompetitors.length)
          ret.push({ index: i, member: this.d_editingTeamCompetitors[i]});
        else
          ret.push({ index: i, member: null});
      }
      this.membersSlots = ret;
      if(this.VERBOSE) console.log('refreshMembersSlots', this.membersSlots);
    },
    checkFormValidity(){
      this.checkEditingCompetitorNameValidity();
      this.checkEditingCompetitorShortnameValidity();

      const valid = this.$refs.form.checkValidity();
      return valid;
    },
    handleOk(bvModalEvent){
      bvModalEvent.preventDefault();
      this.editNonIndividualCompetitor_Ok();
    },
    close(){
      this.$emit("close");
      this.$emit("input", false);
    },
    hide(){
      this.$emit("hide");
      this.$emit("input", false);
    },
    hideDialog(){
      this.$refs['dialog'].hide();
      this.$emit("input", false);
    },
    show(){
      //this.reset(!this.creationMode && this.d_editingCompetitorId != null);
      this.$emit("input", true);
    },
    editSearch_Hit(selected){
        this.d_editingCompetitorId = selected;
        this.d_editingCompetitorId.members = [];
        this.editingCompetitorName = selected.name;
        this.editingCompetitorShortname = (selected.shortname == null || selected.shortname == selected.name) ? '' : selected.shortname;
    },
    async editNonIndividualCompetitor_Ok(){
      if(!this.checkFormValidity()) {
        return;
      }
      await this.$showSaver();
      this.hideDialog();
      //this.saving=true;
      setTimeout(this.completeSaving, 100);
    },
    async completeSaving(){

      var comp = this.d_editingCompetitorId;
      var members = /*this.d_editingCompetitorId.members
                  ? this.d_editingCompetitorId.members.map((m, index) => {
                      if(m.people_id)
                        return { people_id: m.people_id, memberOrder: index};
                      else
                        return { people_id: m.id, memberOrder: index};
                    })
                  : [];*/
                  this.d_editingTeamCompetitors;

      if(this.creationMode){
        if(this.newCompetitor || comp == null){
          comp = CompetitionsConfigurationHelper.create_new_non_individual_competitor(this.COMPETITION.competition_type, this.category, this.editingCompetitorName);
        }
        if(this.VERBOSE) console.log('catCompetitors', this.competition_id, this.level, this.category);
        var catCompetitors = RST_COMPETITOR.query()
                .where("competition_id", this.competition_id)
                .where("level", this.level)
                .where("category", this.category)
                .orderBy("subscription_order")
                .get();
        var lastCatCompetitor = (catCompetitors != null && catCompetitors.length > 0)
                              ? catCompetitors[catCompetitors.length - 1]
                              : null;
        var subscriptionOrder = (lastCatCompetitor != null)
                              ? lastCatCompetitor.subscription_order
                              : 0;
      
        if(members.length > 0)
        {
          await CompetitionsConfigurationHelper.replace_membersAsync(this.competition_id, comp.id, this.round_number, members);
        }

        if(comp != null)
        {
            CompetitionsConfigurationHelper.attach_competitor(this.competition_id, comp.id, this.level, this.category, subscriptionOrder + 1, this.editingCompetitorIsOpen);
            this.$emit('competitorCreated');
        } else {
            throw 'should not come here !';
//                  var competitor = CompetitionsConfigurationHelper.create_new_non_individual_competitor(this.COMPETITION.competition_type, this.category, this.editingCompetitorName);
//                  CompetitionsConfigurationHelper.attach_competitor(this.currentCompetition_id, competitor.id, this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code, subscriptionOrder + 1);
        }
        this.$hideSaver();
        this.toastSaved("Compétiteur ajouté");
        this.reset();
        this.$emit("ok");
      } else {
        var success = true;
        var shortname = this.editingCompetitorShortname;
        if(shortname.trim() == '')
          shortname = this.editingCompetitorName;
        if(this.editingCompetitorName != this.d_editingCompetitorId.name
          || shortname != this.d_editingCompetitorId.shortname)
        {
          success = await CompetitionsConfigurationHelper.updateNonIndividualCompetitorDataAsync(this.d_editingCompetitorId.id, this.editingCompetitorName, shortname);
        }
        if(success && (this.editingCompetitorIsOpen != this.competitor_id.isOpen))
          success = await CompetitionsConfigurationHelper.changeCompetitorIsOpenStatusAsync(this.competition_id, this.editingCompetitorId.id, this.editingCompetitorIsOpen);

        if(success && members.length > 0){
          if(this.VERBOSE) console.log('will replace_membersAsync');
          success = await CompetitionsConfigurationHelper.replace_membersAsync(this.competition_id, comp.competitor_id, this.round_number, members);
        }
        this.reset();
        this.$hideSaver();
        this.toastAsyncSaved(success, "Compétiteur modifié");
        this.$emit("ok");
        this.saving = false;
      }
    },
    newCompetitor_click(){
      this.newCompetitor = true;
      this.creationHelperNoteVariant = 'danger';
      this.d_editingCompetitorId = { members: [] };
    },
    cancelNewCompetitor_click(){
      this.newCompetitor = false;
      this.d_editingCompetitorId = this.competitor_id
      this.editingCompetitorNameIsValid = null;
      this.editingCompetitorShortnameIsValid = null;
      this.creationHelperNoteVariant = 'info';
    },
    reset(exceptEditionFields = false){
      console.warn('reset');
      this.editSearch = '';

      if(!exceptEditionFields)
      {
        this.d_editingCompetitorId = null;
        this.editingCompetitorName = '';
        this.editingCompetitorShortname = '';
      }

      this.editingCompetitorNameIsValid = null;
      this.editingCompetitorShortnameIsValid = null;
      this.newCompetitor = false;
      this.d_editingTeamCompetitors = [];
      this.creationHelperNoteVariant = 'info'
    },
    checkEditingCompetitorNameValidity(){
      this.editingCompetitorNameIsValid = null;
      if((!this.creationMode || this.newCompetitor) && (this.editingCompetitorName == null || this.editingCompetitorName.trim() == ''))
        this.editingCompetitorNameIsValid = false;
      return this.editingCompetitorNameIsValid == null ? true : false;
    },
    checkEditingCompetitorShortnameValidity(){
      this.editingCompetitorShortnameIsValid = null;
      return this.editingCompetitorShortnameIsValid == null ? true : false;
    },
    addTeamMember(currentMember){
      //this.d_editingTeamCompetitors = this.d_editingCompetitorId.members;
      this.d_peopleEditDialogContext = currentMember;
      this.d_peopleEditDialogCreationMode = true; 
      if(this.VERBOSE) console.log('addTeamMember', { d_peopleEditDialogContext: this.d_peopleEditDialogContext, d_editingCompetitorId: this.d_editingCompetitorId})
      if(this.d_editingTeamCompetitors.length == 0 && this.d_editingCompetitorId.members) 
        this.d_editingTeamCompetitors = this.d_editingCompetitorId.members.map(a => a);
      
      this.d_showPeopleEditDialog = true;
    },
    getPeopleForMemberSearch(searched){
      if(searched.length < 2) return;
      searched = searched.toLowerCase();
      var competition_type = this.COMPETITION.competition_type;
      var categ = this.CATEGORY;
      if(this.VERBOSE) console.log('getPeopleForMemberSearch', { searched: searched, competition_type: competition_type, categ: categ, d_editingTeamCompetitors: this.d_editingTeamCompetitors });

      var alreadySetMembersPeopleIds = this.d_editingTeamCompetitors.map(m => m.id)
        .concat(
          RST_COMPETITOR_COMPOSITION.query()
            .where('competition_id', this.competition_id)
            .where('round_number', this.round_number)
            .where(cc => cc.COMPETITOR && cc.COMPETITOR.category == categ.code)
            .where(cc => cc.name.toLowerCase().includes(searched))
            .get()
            .map(cc => cc.people_id)
        );
      
      var suggestions = COMPETITOR.query()
        .where('competition_type', competition_type)
        .where(item => {
          const cat = item.CATEGORY;
          return cat.isIndividual
            && cat.isCva == categ.isCva
            && cat.isIndoor == categ.isIndoor
            && cat.isFreestyle == categ.isFreestyle
            && cat.isDualLines == categ.isDualLines
            && cat.isMultiLines == categ.isMultiLines;
        })
        .where(p => p.name.toLowerCase().includes(searched))
        .where(item => !alreadySetMembersPeopleIds.includes(item.people_id))
        .get()
        .map(item => {
          alreadySetMembersPeopleIds.push(item.people_id);
          var mapped = item.PEOPLE;
          mapped.forSuggestion = '** ' + mapped.name + ((mapped.isFederal == true) ?  ' - ' + mapped.federal_number : '');
          mapped.matches = '**'
          return mapped;
        })
        .concat(
          // On ajoute à ces suggestion la liste des pilotes 4L si la catégorie est 2L est vice-versa.
          COMPETITOR.query()
            .where('competition_type', competition_type)
            .where(item => {
              const cat = item.CATEGORY;
              return cat.isIndividual
                && cat.isCva == categ.isCva
                && cat.isIndoor == categ.isIndoor
                && cat.isFreestyle == categ.isFreestyle
                && !(cat.isDualLines == categ.isDualLines
                    && cat.isMultiLines == categ.isMultiLines);
            })
            .where(p => p.name.toLowerCase().includes(searched))
            .where(item => !alreadySetMembersPeopleIds.includes(item.people_id))
            .get()
            .map(item => {
              alreadySetMembersPeopleIds.push(item.people_id);
              var mapped = item.PEOPLE;
              mapped.forSuggestion = '* ' + mapped.name + ((mapped.isFederal == true) ?  ' - ' + mapped.federal_number : '');
              mapped.matches = '*'
              return mapped;
            })
        )
        .concat(
          PEOPLE.query()
            .where(p => p.name.toLowerCase().includes(searched))
            .where(item => !alreadySetMembersPeopleIds.includes(item.id))
            .get()
            .map(people => {
              var mapped = people;
              mapped.forSuggestion = people.name + ((people.isFederal == true) ? ' - ' + people.federalNumber : '');
              mapped.matches = '';
              return mapped;
            })
        );  
        this.d_peopleEditDialogSearchSuggestions = suggestions;
    },
    editTeamMember(currentMember){
      if(this.VERBOSE) console.log('editTeamMember', currentMember);
      this.d_peopleEditDialogContext = currentMember;
      this.d_peopleEditDialogCreationMode = false; 
      this.d_showPeopleEditDialog = true;
    },
    removeTeamMember(member){
      if(this.VERBOSE) console.log('removeTeamMember', {d_editingTeamCompetitors: this.d_editingTeamCompetitors, member: member} );
     /* var index = this.d_editingCompetitorId.members.findIndex(m => m.people_id == (member.people_id || member.id));
      if(index >= 0){
        if(this.VERBOSE) console.log('cas 1', index);
        this.d_editingCompetitorId.members.splice(index,1);
        this.$refs.teamMembers.refresh();
      } else {*/
        var index = this.d_editingTeamCompetitors.findIndex(m => m.people_id == (member.people_id || member.id ));
        if(this.VERBOSE) console.log('cas 2', index);
        if(index >= 0){
          this.d_editingTeamCompetitors.splice(index,1);
          //this.$refs.teamMembers.refresh();
          if(this.VERBOSE) console.log('after splice', this.d_editingTeamCompetitors);
        }
      //}
      this.refreshMembersSlots();
    },
    async member_select(data){
      data.people.people_id = data.people.id;
      this.setMember(data);
    },
    editCompetitorLicensee(competitor){
      this.selected_d_editingCompetitorId = competitor;
      this.d_peopleEditDialogContext = competitor;
      var people = PEOPLE.query().where("id", competitor.people_id).first();
      this.selectedLicensee = people.federalNumber;
      this.selectedLicencePeopleName= people.name;
      this.selectedLicenceYear = this.COMPETITION.year;
      this.showEditLicenseeDialog = true;
    },
    async member_create(data){
      await this.showSaver();
      try{
        var comp = this.COMPETITION;
        var compType = comp.competition_type;
        var regulation = comp.REGULATION;
        var compCateg = this.CATEGORY;
        var categ = REF_CATEGORY.query()
                      .where("isDualLines", compCateg.isDualLines)
                      .where("isMultiLines", compCateg.isMultiLines)
                      .where("isCva", regulation.isCva)
                      .where("isIndoor", regulation.isIndoor)
                      .where("isFreestyle", regulation.isFreestyle)
                      .first();
        var result = await CompetitionsConfigurationHelper.create_new_individual_competitor_from_scratchAsync(compType, categ.code, data.firstname, data.lastname, data.federal_number, data.sexe, data.birthdate, data.email);
        if(this.VERBOSE) console.log('data/result', { data: data, result: result});
        if(result.success && data.licenseeState != null && data.context.federalNumber != null && typeof data.context.federalNumber === 'object' && (data.licenseeState.isValid != data.context.federalNumber.isValid 
          || data.licenseeState.isCompetitor != data.context.federalNumber.isCompetitor
          || data.licenseeState.isMedicalCertificateValid != data.context.isMedicalCertificateValid)){
          result.success = await CompetitionsConfigurationHelper.changeLicenceInfoAsync(data.federal_number, data.licenseeState.year, data.licenseeState.isValid, data.licenseeState.isCompetitor, data.licenseeState.isMedicalCertificateValid);
          if(this.VERBOSE) console.log('changeLicenceInfo A', result.success);
        }else if(result.success && data.licenseeState != null){
          result.success = await CompetitionsConfigurationHelper.changeLicenceInfoAsync(data.federal_number, data.licenseeState.year, data.licenseeState.isValid, data.licenseeState.isCompetitor, data.licenseeState.isMedicalCertificateValid);
          if(this.VERBOSE) console.log('changeLicenceInfo B', result.success);
        }
        if(this.VERBOSE) console.log('data', data);
        data.people = result.people;
        data.people.people_id = data.people.id;
        this.setMember(data);
        if(this.VERBOSE) console.log('Avant toastAsyncSaved', result.success);
        this.toastAsyncSaved(result.success, 'Compétiteur créé');
      }catch(error){
        this.toastSaveFailure(error);
      }
      this.$hideSaver();
    },    
    async member_update(data){
      await this.showSaver();
      try{
        var compCateg = this.CATEGORY;
        var competitor = COMPETITOR.query().where("people_id", data.context.people_id)
                          .where("competition_type", this.COMPETITION.competition_type)
                          .where(c => c.CATEGORY.isIndividual && c.CATEGORY.isDualLines == compCateg.isDualLines && c.category.isMultiLines == compCateg.isMultiLines)
                          .first();
        var result = await CompetitionsConfigurationHelper.updateIndividualCompetitorDataAsync(competitor.id, data.firstname, data.lastname, data.federal_number);
        if(result && (data.licenseeState.isValid != data.context.federalNumber.isValid 
          || data.licenseeState.isCompetitor != data.context.federalNumber.isCompetitor
          || data.licenseeState.isMedicalCertificateValid != data.context.isMedicalCertificateValid))
          result = await CompetitionsConfigurationHelper.changeLicenceInfoAsync(data.federal_number, data.licenseeState.year, data.licenseeState.isValid, data.licenseeState.isCompetitor, data.licenseeState.isMedicalCertificateValid);
        competitor = COMPETITOR.query().where("id", competitor.id).first();
        data.people = {...competitor.PEOPLE, ...data.licenseeState};
        this.toastAsyncSaved(result, "Compétiteur modifié");
        this.setMember(data);
      }catch(error){
        this.toastSaveFailure(error);
      }
      this.$hideSaver();
    },
    async editLicenseeDialog_ok(newState){
      var data = newState.context;
      data.federalNumber.isValid = newState.isValid;
      data.federalNumber.isCompetitor = newState.isCompetitor;
      data.federalNumber.isMedicalCertificateValid = newState.isMedicalCertificateValid;
      this.d_editingCompetitorId.members.splice(newState.context.index, 1, data);
      this.$refs.teamMembers.refresh();
    },
    setMember(data){
      if(this.VERBOSE) console.log('setMember', { data: data, members: this.d_editingTeamCompetitors, membersLength: this.d_editingTeamCompetitors.length});
      if(data.licenseeState && data.licenseeState.federal_number != null && data.licenseeState.federalNumber != ''){
        if(this.VERBOSE) console.log('look for licensee');
        const savedLicenseeState = LICENSEE.query().where("federal_number", data.licenseeState.federal_number).where("year", data.licenseeState.year).first();
        if(this.VERBOSE) console.log('licensee found', { savedLicenseeState: savedLicenseeState, newLicenseeState: data.licenseeState});
        if(savedLicenseeState === null 
          || data.licenseeState.isValid != savedLicenseeState.isValid 
          || data.licenseeState.isCompetitor != savedLicenseeState.isCompetitor
          || data.licenseeState.isMedicalCertificateValid != savedLicenseeState.isMedicalCertificateValid
        ){
          if(this.VERBOSE) console.log('emit licenseeInformationChange');
          this.$emit('licenseeInformationChange', data.licenseeState);
        }
      }
      if(!data.context || this.d_editingTeamCompetitors.length <= data.context.index){
        if(this.VERBOSE) console.log('push')
        // Théoriquement, on est censé intégrer un élément "RST_COMPETITOR" ou "RST_COMPETITOR_COMPOSITION" (avec un propriété people), mais
        // en cas de création de personne "à la volée", on récupère directement, dans data, l'objet PEOPLE.
        // Dans ce cas il faut le transformer pour que cela puisse passer !
        this.d_editingTeamCompetitors.push(data.people);
      }
      else{
        if(this.VERBOSE) console.log('splice')
         this.d_editingTeamCompetitors.splice(data.context.index, 1, data.people);
      }

      if(this.VERBOSE) console.log('setMember', this.d_editingTeamCompetitors);
//      this.d_editingTeamCompetitors = this.competitor_id.members;
      //this.$refs.teamMembers.refresh();
      this.refreshMembersSlots();
    },
    emitSearch(search){
      this.$emit('search', search);
    },
    refreshMembersList(){
      if(this.VERBOSE) console.log('refreshMembersList', this.$refs, this.$refs.teamMembers);
      this.$refs.teamMembers.refresh();
    }
  },
  watch:{
    editSearch: debounce(function(search) { this.emitSearch(search)}, 200),
    search_suggestions: function (newVal){
        this.searchSuggestions = newVal;
    },
    value: function(newVal)
    {
      this.modalShown = newVal;
    },
    competitor_id(newVal)
    {
      if(this.VERBOSE) console.log('watch competitor_id', newVal);
        this.d_editingCompetitorId = newVal;
        this.d_editingTeamCompetitors = newVal.members;
        this.editingCompetitorName = newVal.name;
        this.editingCompetitorShortname = (newVal.shortname == null || newVal.shortname.trim() == newVal.name.trim()) ? '' : newVal.shortname;
        this.editingCompetitorIsOpen = newVal.isOpen;
        if(this.VERBOSE) console.log({d_editingCompetitorId: this.d_editingCompetitorId, d_editingTeamCompetitors: this.d_editingTeamCompetitors})
        this.refreshMembersSlots();
    },
    members(newVal)
    {
      this.d_editingTeamCompetitors = newVal;
      if(this.VERBOSE) console.log('watch members', { newVal : newVal, d_editingTeamCompetitors : this.d_editingTeamCompetitors, refs: this.$refs });
      //this.refreshMembersList();
      this.refreshMembersSlots();
    },
    minMemberCount(newVal)
    {
      this.d_minMemberCount = newVal;
      this.refreshMembersSlots();
    },
    maxMemberCount(newVal){
      this.d_maxMemberCount = newVal;
      this.refreshMembersSlots();
    },
  }
}
</script>

<template>
  <div>
    <b-modal
      :title="creationMode ? 'Ajout de compétiteur' : 'Modification de compétiteur'"
      centered
      :static="true"
      :lazy="true"
      v-model="modalShown"
      button-size="lg"
      @ok="handleOk"
      @close="close"
      @hidden="hide"
      @show="show"
      ref="dialog"
    >
        <vue-bootstrap-typeahead
            v-show = 'creationMode && !newCompetitor'
            v-model="editSearch"
            :data="searchSuggestions"
            :placeholder="d_maxMemberCount > 2 ? 'Entrez le nom de l\'équipe' : 'Entrez le nom de la paire'"
            :minMatchingChars="1"
            prepend="Rechercher"
            @hit="editSearch_Hit"
            :serializer="s => s.name"
        />
        <helper-note v-show="creationMode" :variant="creationHelperNoteVariant">
            <div>Privilégiez toujours la recherche.<br/>
            Si aucun résultat ne correspond, essayez de modifier l'orthographe.<br/>
            Si, finalement, vous ne trouvez vraiment pas le compétiteur, ou s'il s'agit d'un nouveau compétiteur inconnu dans la base, saisissez les informations ci-dessous
            </div>
            <b-button variant="secondary" size="sm" @click="newCompetitor_click" :disabled="newCompetitor">Nouveau</b-button>
            <b-button variant="secondary" size="sm" @click="cancelNewCompetitor_click" :disabled="!newCompetitor">Annuler Nouveau compétiteur</b-button>
        </helper-note>

        <checkbox-form-field label='Concours en "open"' v-if="competitionIsOpen && !creationMode" caption='Concours en mode "open"' v-model="editingCompetitorIsOpen" switch>
        <br/>
        </checkbox-form-field>

        <b-form ref="form" @submit.stop.prevent="editNonIndividualCompetitor_Ok">
          <form-field caption="Nom" required >
            <b-form-input type="text"
              v-model="editingCompetitorName"
              required
              :disabled="creationMode && !newCompetitor"
              :state="editingCompetitorNameIsValid"
              @blur="checkEditingCompetitorNameValidity"
              @input="editingCompetitorNameIsValid = null"
              invalidFeedBack="Le nom est obligatoire."
            />
          </form-field>

          <form-field caption="Nom abbrégé (court)" >
            <b-form-input type="text"
              v-model="editingCompetitorShortname"
              :disabled="creationMode && !newCompetitor"
              :state="editingCompetitorShortnameIsValid"
              @blur="checkEditingCompetitorShortnameValidity"
              @input="editingCompetitorShortnameIsValid = null"
            />
          </form-field>

          <checkbox-form-field label='Concours en "open"' v-if="competitionIsOpen && creationMode" caption='Concours en mode "open"' v-model="editingCompetitorIsOpen" switch>
          </checkbox-form-field>

          <form-field caption="Co-équipiers" >
            <b-container fluid>
              <team-members-list
              v-if="false"
                :minMemberCount="d_minMemberCount"
                :maxMemberCount="d_maxMemberCount"
                :showName="true"
                :showRollnumber="true"
                :canAdd="true"
                :canEdit="true"
                :canRemove="true"
                :members="d_editingTeamCompetitors"
                :competitor="d_editingCompetitorId"
                @add="addTeamMember"
                @search="addTeamMember"
                @edit="editTeamMember"
                @remove="removeTeamMember"
                @editLicensee = 'editCompetitorLicensee'
                ref="teamMembers"
              >
              </team-members-list>
              <flex-panel justify-items="stretch" direction="column" >
                <flex-panel style="align-content: stretch;" justify-items="stretch" class="container" v-for="memberSlot in membersSlots" :key="memberSlot.index" justify-content="space-between">
                  <b-button style="flex-grow:0;" size="sm" variant="success" @click="addTeamMember(memberSlot)"><b-icon-search></b-icon-search></b-button>
                  <b-button v-if="false && memberSlot.member != null" style="flex-grow:0;" size="sm" variant="secondary" @click="editTeamMember(memberSlot.member)"><b-icon-pencil></b-icon-pencil></b-button>
                  <div style="flex-grow=2"><b v-if="memberSlot.member != null">{{ memberSlot.member.firstname }} {{memberSlot.member.lastname}}</b></div>
                  <federal-number style="justify-self: right;width:7em; border:solid 1px black; flex-grow:0;" :is-federal="COMPETITION.isFederal" should-be-competitor v-if="memberSlot.member!=null" v-model="memberSlot.member.LICENSEE" force-edit @edit="editCompetitorLicensee(memberSlot.member)"></federal-number>
                  <b-button style="flex-grow:0;"  variant="danger" size="sm" v-if="memberSlot.member !=null" @click="removeTeamMember(memberSlot.member)"><b-icon-trash-fill></b-icon-trash-fill></b-button>
                </flex-panel>
              </flex-panel>
            </b-container>
         </form-field>
        </b-form>
    </b-modal>

    <!-- Dialogue d'ajout/édition d'un équipier -->
    <people-edit-dialog
      v-model="d_showPeopleEditDialog"
      :editCallContext="d_peopleEditDialogContext"
      :creationMode="d_peopleEditDialogCreationMode"
      creationModeTitle="Recherche de pilote"
      editionModeTitle="Modification des données du pilote"
      :search_suggestions="d_peopleEditDialogSearchSuggestions"
      :fedralNumberRequired="COMPETITION.COMPETITION_TYPE.isFederal"
      @search="getPeopleForMemberSearch"
      @select="member_select"
      @create="member_create"
      @update="member_update"
    >
    </people-edit-dialog>

    <!-- Dialogue de traitement de l'état de validité de la license d'un pilote -->
    <licensee-edit-dialog
      ref="licenceDialog"
      v-model="showEditLicenseeDialog"
      @hide="showEditLicenseeDialog = false"
      @ok="editLicenseeDialog_ok"
      :callContext="d_peopleEditDialogContext"
      :federalNumber="selectedLicensee"
      :year="selectedLicenceYear"
      :peopleName="selectedLicencePeopleName"
    >
    </licensee-edit-dialog>
  </div>
</template>

